<template>
	<div>
		<div class="container">
			<div class="header">
				<a @click="goback"><img src="../assets/images/back.png" alt=""></a> 图书详情</div>
			<div class="blian22"></div>
			<div class="conthead flex_outside">
				<div class="left">
					<!-- <img :src="detail.facePic" alt=""> -->
					<img v-if="detail.facePic != null " :src="detail.facePic" alt="">
					<img v-else src="../assets/images/default-book.png" alt="">
				</div>
				<div class="right flex_inside">
					<!-- ❀ -->
					<!-- （{{ detail.grade }}{{ detail.SUBJECT }}{{ detail.volumes }}） -->
					<h3 class="ellipsis_two">{{ detail.lessonName }}</h3>
					<!--<button>加入书架</button>-->
				</div>
			</div>
			
		</div>
		<div class="demand-cont mt5">
			<div v-if="resourcePathNew" class="title">
				<p @click="allBook" >整本下载</p>
			</div>
			<div style="height: 10px;"></div>
			<div class="title">
				<!-- ❀ -->
				<p>资源目录</p>
			</div>
			<div class="directory">
				<ul>
					<li v-for="(item,index) in contents" :key="index">
						<!--<router-link  :to="{path:'./unitsummary?id=' + item.contentsId}">
								<span>{{item.contents}}</span>
								<img src="../assets/images/right1.png" alt="">
							</router-link>-->
						<div class="f-n" @click="lookmu(index)">
							<img src="../assets/images/r1.png" alt="" class="imgl" :class="shouMindex == index ? 'rotates' : ''">
							<span>{{item.label}}</span>
						</div>
						<div v-if="shouMindex == index">
							<div class="f-n" v-for="(citem,cindex) in item.children" :key="cindex">
								<!--<router-link :to="{path:'./unitsummary?id=' + citem.chapterId}">
									<img src="../assets/images/w.png" alt="" class="imgl">
									<span style="color:#5A5A5A;font-weight: 400;">{{citem.label}}</span>
								</router-link>-->
								<a @click="goNext(citem.chapterId)">
									<!--<router-link :to="{path:'./unitsummary?chapterId=' + citem.chapterId}">-->
									<img src="../assets/images/w.png" alt="" class="imgl">
									<span style="color:#5A5A5A;font-weight: 400;">{{citem.label}}</span>
								</a>
							</div>
						</div>
					</li>
				</ul>
			</div>
		</div>
	</div>
</template>
<script>
	import { preDetail, preparationContents } from "@/assets/js/api";
	import { Indicator } from 'mint-ui'; //加载样式
	export default {
		name: "demandcont",
		data() {
			return {
				detail: {},
				shouMindex: 0,
				contents: [],
				/* 整本下载 */
				resourcePath:"",
				resourcePathNew:"",
				resourceName:""
			};
		},
		methods: {
			goNext(chapterId) {
				this.$router.push({
					path: '/unitsummary',
					query: {
						id: chapterId,
					}
				});
				console.log(this.shouMindex)
				localStorage.setItem('shouMindex', this.shouMindex);
			},
			goback() {
				this.$router.go(-1);
				localStorage.setItem('shouMindex', '');
			},
			//获取资源详情
			getpreDetail() {
				Indicator.open()
				preDetail({
					prepareId: this.$route.query.id
				}).then(res => {
					if(res.code == 0) {
						this.detail = res.detail;
						let facePic = res.detail.facePic
						if(facePic.indexOf("null") != -1){
							this.detail.facePic = null
						}
						// console.log(res.detail.prepareFile)
						this.resourcePath = res.detail.prepareFile
						/* 去掉空格 */
						if(res.detail.prepareFile){
							this.resourcePathNew = res.detail.prepareFile.replace(/\s/g, "")
						}
						this.resourceName = res.detail.prepareFileName
						document.title = res.detail.lessonName;
						Indicator.close()
					} else {
						Indicator.close()
					}
				});
			},
			//目录列表
			getpreparationContents() {
				preparationContents({
					prepareId: this.$route.query.id
				}).then(res => {
					if(res.code == 0) {
						this.contents = res.contents;
					}
				});
			},
			//查看目录
			lookmu(index) {
				if(this.shouMindex === index) {
					this.shouMindex = '';
				} else {
					this.shouMindex = index;
				}
			},
			// 整本下载
			allBook(){
				if(this.resourcePathNew){
					this.$router.push({
						path: './unitsummaryZip',
						query: {
							path: this.basUrl + this.resourcePath,
							titleName: this.resourceName
						}
					});
				}else{
					Toast({
						message: '暂无整本资源下载',
						position: 'middle',
						duration: 1000
					});
				}
			}
		},
		mounted() {
			if(localStorage.getItem('shouMindex')) {
				this.shouMindex = localStorage.getItem('shouMindex');
			}
			this.getpreDetail();
			this.getpreparationContents();
		}
	};
</script>