<template>
	<div>
		<div class="container">
			<div class="header">
				<a @click="goback"><img src="../assets/images/back.png" alt=""></a> 图书详情</div>
			<div class="blian22"></div>
			<div class="conthead flex_outside" style="overflow: auto;overflow-y: hidden;">
				<div class="left" style="height: 8rem;line-height: 8rem;">
					<!-- <img :src="detail.bookPic" alt=""> -->
					<!-- <span>{{ detail.bookPic }}</span> -->
					<!-- && detail.bookPic != null -->
					<!-- (detail.bookPic).indexOf("null") != -1 -->
					<!-- detail.bookPic !='' && -->
					<img v-if="detail.bookPic != null " :src="detail.bookPic" alt="">
					<img v-else src="../assets/images/default-book.png" alt="">
					<!-- <img src="../assets/images/favicon.png" style="width: 105px; height: 150px;" alt=""> -->
				</div>
				<div class="right flex_inside" style="height: 8rem;">
					<h3 class="ellipsis_two" style="margin: 0.1rem 0">{{ detail.bookName }}</h3>
					<div class="ellipsis_one book_info">{{ detail.author }}</div>
					<div class="ellipsis_one book_info">{{ detail.publication }}</div>
					<div class="ellipsis_one book_info">{{ detail.isbn }}</div>
					<div class="ellipsis_one book_info look_jian" @click="lookJian(detail.bookDesc)">简介</div>
					<template v-if="userId">
						<button @click="removeBook" v-if="detail.isAdd"> 移出书架 </button>
						<button @click="addBookShelf" v-else>加入书架 </button>
					</template>
				</div>
			</div>
		</div>

		<div class="demand-cont mt5">
			<div v-if="resourcePathNew" class="title">
				<p @click="allBook" >整本下载</p>
			</div>
			<div style="height: 10px;"></div>
			<div class="title">
				<!-- ❀ -->
				<p>资源目录</p>
			</div>
			<div class="directory">
				<ul>
					<li v-for="(item,index) in directoryArr" :key="index">
						<!--<router-link :to="{path:'./unitsummary'}">
              	<img src="../assets/images/r1.png" alt="" class="imgl">
                <span>{{item.name}}</span>
              </router-link>-->
						<div class="f-n" @click="lookmu(item.contentsId)">
							<img src="../assets/images/r1.png" alt="" class="imgl" :class="shouMindex == item.contentsId ? 'rotates' : ''">
							<span>{{item.label}}</span>
						</div>
						<div v-if="shouMindex == item.contentsId">
							<div class="f-n" v-for="(citem,cindex) in item.children" :key="cindex">
								<a @click="goNext(citem.chapterId)">
									<img src="../assets/images/w.png" alt="" class="imgl">
									<span style="color:#5A5A5A;font-weight: 400;">{{citem.label}}</span>
								</a>
							</div>
						</div>
					</li>
				</ul>
			</div>
		</div>
		<div class="container mt5">
			<div class="demand-cont">
				<div class="title">推荐书籍</div>
				<div class="books-list">
					<ul>
						<li class="flex_outside" v-for="(item,index) in booksArr" :key="index" @click="goTui(item.bookId)">
							<div class="left">
								<!-- <img :src="item.bookPic" />   -->
								<img v-if=" item.bookPic != null " :src="item.bookPic" alt="">
								<img v-else src="../assets/images/default-book.png" alt="">
							</div>
							<div class="right flex_inside">
								{{item.bookName}}（{{ item.learnSection }}{{ item.grade }}{{ item.SUBJECT }}）
							</div>
						</li>
					</ul>
				</div>
			</div>
		</div>
		<!--<div>获取地址上目录的id:{{ a }}</div>-->
		<!--<div>图书的id:{{ b }}</div>-->
		<!--<div>获取目录之后的id:{{ c }}</div>-->
		<!--<div>没有id之后的id:{{ d }}</div>-->
		<div class="goHome" v-if="isTwoCode" @click="goHome">
			<img src="../assets/images/goHmone2.png" />
		</div>
		<QrCode @hidden="hiddenShow" v-show="showQrCode" :codeurl="codeUrl"></QrCode>

		<mt-popup v-model="popupVisible" position="bottom" style="width: 100%;padding: 0.5rem 0.3rem;box-sizing: border-box;">
			<div class="infoBox">
				{{ bookDesc }}
			</div>
		</mt-popup>
	</div>
</template>
<script>
	import { bookDetail, bookContents, recBooks, addBookShelf, removeBook, qrcodeUrl, wxUserAuth, isSubscribe } from "@/assets/js/api";
	import { Indicator, Toast, Popup } from 'mint-ui'; //加载样式
	import QrCode from '@/components/QrCode.vue';
	export default {
		name: "demandcont",
		data() {
			return {
				a: '',
				b: '',
				c: '',
				d: '',
				userId: localStorage.getItem('userId'),
				/*微信授权部分参数*/
				isTwoCode: false, //是否是扫描二维码进入的
				code: '', //微信回调code
				appid: '', //微信回调appid
				redirectUrl: '', //当前页面地址
				token: '',
				codeUrl: '',
				showQrCode: false,
				/**/
				id: '',
				shouMindex: '',
				detail: {},
				// 目录 
				directoryArr: [],
				booksArr: [],
				popupVisible: false,
				bookDesc:'',
				
				/* 整本下载 */
				resourcePath:"",
				resourcePathNew:"",
				resourceName:""
			};
		},
		components: {
			QrCode,
		},
		methods: {
			//查看简介
			lookJian(info) {
				this.bookDesc = info;
				if(info) {
					this.popupVisible = true;
				}
			},

			//去首页
			goHome: function() {
				this.$router.replace({ //核心语句
					path: '/', //跳转的路径
				})
			},
			//
			goNext(chapterId) {
				this.$router.push({
					path: '/unitsummary',
					query: {
						id: chapterId,
					}
				});
				localStorage.setItem('shouMindex', this.shouMindex);
			},
			goTui(id) {
				console.log(this.$route)
				if(this.$route.path == '/demandcont') {
					this.id = id; //图书id
					this.shouMindex = ''; //章节id
					this.getbookDetail(); //图书详情
					this.getbookContents(); //图书目录
					this.getrecBooks();
				}
			},

			goback() {
				this.$router.back();
				localStorage.setItem('shouMindex', '');
			},
			//查看目录
			lookmu(index) {
				if(this.shouMindex === index) {
					this.shouMindex = '';
				} else {
					this.shouMindex = index;
				}
			},
			//获取图书详情
			getbookDetail() {
				Indicator.open()
				bookDetail({
					bookId: this.id
				}).then(res => {
					if(res.code == 0) {
						this.detail = res.book;
						// this.detail.bookPic = res.book.bookPic
						let bookPic = res.book.bookPic
						if(bookPic.indexOf("null") != -1){
							this.detail.bookPic = null
						}
						this.resourcePath = res.book.bookFile
						/* 去掉空格 */
						if(res.book.bookFile){
							this.resourcePathNew = res.book.bookFile.replace(/\s/g, "")
						}
						this.resourceName = res.book.bookFileName
						// console.log(this.detail.bookPic)
						document.title = res.book.bookName;
						//判断是否是分享进来的
						Indicator.close()
					} else {
						Indicator.close()
					}
				});
			},
			//获取图书目录
			getbookContents() {
				bookContents({
					bookId: this.id
				}).then(res => {
					if(res.code == 0) {
						this.directoryArr = res.contents;
						this.c = this.shouMindex;
						if(!this.shouMindex) {
							//为空则不是  index等于第一个
							if(res.contents.length > 0) {
								this.shouMindex = res.contents[0].contentsId;
							}
							this.d = this.shouMindex;
						}
					}
				});
			},
			//加入书架
			addBookShelf() {
				addBookShelf({
					bookId: this.id
				}).then(res => {
					if(res.code == 0) {
						Toast({
							message: '操作成功',
							iconClass: 'icon icon-success',
							duration: 1000
						});
						this.getbookDetail()
					}
				});
			},
			//移除书架
			removeBook() {
				removeBook({
					bookId: this.id
				}).then(res => {
					if(res.code == 0) {
						Toast({
							message: '操作成功',
							iconClass: 'icon icon-success',
							duration: 1000
						});
						this.getbookDetail()
					}
				});
			},

			//获取推荐数书籍
			getrecBooks() {
				recBooks({
					bookId: this.id
				}).then(res => {
					if(res.code == 0) {
						let temp = res.recBooks
						temp.map((item,index)=>{
							if(item.bookPic.indexOf("null") != -1){
								item.bookPic = null
							}
						})
						this.booksArr = temp;
					}
				});
			},
			//二维码弹框的显示隐藏
			hiddenShow: function() {
				this.showQrCode = false
			},
			//获取公众号二维码信息
			getQrcodeUrl() {
				let chapterId = '';
				//				if(this.directoryArr.length>0 && this.directoryArr[0].children.length>0){
				//					chapterId = this.directoryArr[0].children[0].chapterId;
				//				};
				if(this.directoryArr.length > 0) {
					for(var i = 0; i < this.directoryArr.length; i++) {
						if(this.directoryArr[i].contentsId == this.shouMindex && this.directoryArr[i].children.length > 0) {
							chapterId = this.directoryArr[i].children[0].chapterId
						}
					}
				}
				//type==2 是进入demandcont
				qrcodeUrl({
					chapterId: chapterId,
					type: 2
				}).then(res => {
					if(res.code == 0) {
						this.showQrCode = true; //二维码显示
						this.codeUrl = res.codeUrl;
					}
				});
			},
			//请求微信授权
			wxUserAuth() {
				wxUserAuth({
					redirectUrl: this.redirectUrl,
					bookId: this.id,
				}).then(res => {
					if(res.code == 0) {
						window.location.href = res.url
					}
				});
			},
			//判断是否关注过微信号
			isSubscribe() {
				isSubscribe({
					appid: this.appid,
					code: this.code,
				}).then(res => {
					if(res.code == 0) {
						if(!res.isSubcribe) {
							this.getQrcodeUrl(); //没有关注过拉取关注二维码信息
						}
						sessionStorage.setItem('isSubcribe', 1)
					}
				});
			},
			// 整本下载 
			allBook(){
				if(this.resourcePathNew){
					this.$router.push({
						path: './unitsummaryZip',
						query: {
							path: this.basUrl + this.resourcePath,
							titleName: this.resourceName
						}
					});
				}else{
					Toast({
						message: '暂无整本资源下载',
						position: 'middle',
						duration: 1000
					});
				}
				
			}
		},
		//		watch: {
		//			id() {
		//				this.getbookDetail(); //图书详情
		//				this.getbookContents(); //图书目录
		//				this.getrecBooks()
		//				console.log('-------------')
		//			}
		//		},
		//		activated() {
		//			this.id = this.$route.query.id;
		//		},
		mounted() {
			this.id = this.$route.query.id; //图书id
			//判断是否是扫描二维码进来的 根据 contentsId (目录id)
			if(localStorage.getItem('shouMindex')) {
				this.shouMindex = localStorage.getItem('shouMindex');
			}

			if(this.$route.query.contentsId) {
				//如果有目录id就是扫描二维码进来的
				this.shouMindex = this.$route.query.contentsId; //章节id
				this.isTwoCode = true; //是否是扫描二维码进入的 控制回首页的按钮
				if(!this.$route.query.token) {
					//	this.a = this.$route.query.contentsId;
					//	this.b = this.id;
					//没有token请求微信登录
					if(this.$route.query.code) {
						//有code说明是微信回调
						this.code = this.$route.query.code;
						this.appid = this.$route.query.appid;
						if(!sessionStorage.getItem('isSubcribe')) {
							this.isSubscribe()
						}
					} else {
						this.redirectUrl = window.location.href; //获取当前地址
						this.wxUserAuth(); //请求微信登录授权
					}
				} else {
					//从公众号进入有token
					this.token = this.$route.query.token;
					localStorage.setItem('token', this.$route.query.token); //保存登陆token
				}
			}
			this.getbookDetail(); //图书详情
			this.getbookContents(); //图书目录
			this.getrecBooks(); //推荐书籍
		}
	};
</script>
<style>
	.book_info {
		color: rgb(90, 90, 90);
		font-size: 0.7rem;
		padding: 0.2rem 0;
	}
	
	.look_jian {
		color: #40A5FF;
		text-align: right;
	}
	
	.goHome {
		width: 2rem;
		height: 2rem;
		position: fixed;
		right: 0.5rem;
		bottom: 2rem;
		box-shadow: 0 0 6px rgba(0, 0, 0, .12);
		border-radius: 50%;
		background-color: #FFFFFF;
	}
	
	.goHome img {
		width: 1.5rem;
		height: 1.5rem;
		border-radius: 50%;
		margin-top: 0.25rem;
		margin-left: 0.25rem;
	}
	
	.infoBox {
		font-size: 0.8rem;
	}
</style>